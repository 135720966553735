<template>
  <div class="senha col-md-12">
    <div class="col-md-8 px-0">
      <ValidationObserver
        tag="form"
        ref="formSenha"
        class="row align-items-end"
        @submit.prevent="sendSenha()"
      >
        <ValidationProvider
          tag="div"
          class="col-md-4"
          v-slot="{ errors, ariaMsg, classes }"
          rules="required|min:5|confirmed:confirm"
          name="Senha"
          id="newPassCont"
        >
          <label class="label_default" :class="classes">Nova senha:</label>
          <input
            type="password"
            class="input_default form-control"
            :class="classes"
            v-model="senha"
          />
          <span v-bind="ariaMsg" :class="classes" class="position-absolute">{{
            errors[0]
          }}</span>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="col-md-4"
          v-slot="{ errors, ariaMsg, classes, validate }"
          rules="required"
          vid="confirm"
          @blur.native="validate($event)"
          name="Confirmação senha"
          id="passConfirmCont"
        >
          <label class="label_default">Confirmar nova senha:</label>
          <input
            type="password"
            class="input_default form-control"
            v-model="confirmaSenha"
          />
        </ValidationProvider>
        <div class="col-md-4">
          <button class="btn_default">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-show="disabled"
            ></span>
            {{ !disabled ? "Salvar" : "" }}
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";

export default {
  data() {
    return {
      senha: "",
      confirmaSenha: "",
      disabled: false
    };
  },
  mounted() {},
  methods: {
    sendSenha() {
      this.$refs.formSenha.validate().then(success => {
        if (success) {
          this.disabled = true;
          HTTP.post(
            `services/app/User/ChangePassword`,
            { password: this.senha },
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getterToken}`
              }
            }
          )
            .then(response => {
              this.disabled = false;
              this.senha = this.confirmaSenha = "";
              this.$refs.formSenha.reset();
              this.toast('Senha alterada', this.$toast.success)
            })
            .catch(error => {
              let errorObject = JSON.parse(JSON.stringify(error));
              this.toast('Erro ao alterar a senha, tente novamente', this.$toast.error)
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  height: 110px;
}
.input_default {
  border: 1px solid #e3e3e3 !important;
  color: #ffffff;
  font-size: 14px;
  height: 40px;
  background-color: #6198c2 !important;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
label,
p {
  color: #ffffff;
}
input.failed {
  border-color: #dc3545 !important;
}
span.failed {
  font-size: 10px;
  color: #dc3545 !important;
  padding: 3px;
} @media screen and (max-width:767px){
  #newPassCont{
    margin-bottom: 5%;
  }
  #passConfirmCont{
    margin-bottom: 5%;
  }
}
</style>
