var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"senha col-md-12"},[_c('div',{staticClass:"col-md-8 px-0"},[_c('ValidationObserver',{ref:"formSenha",staticClass:"row align-items-end",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.sendSenha()}}},[_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"tag":"div","rules":"required|min:5|confirmed:confirm","name":"Senha","id":"newPassCont"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{staticClass:"label_default",class:classes},[_vm._v("Nova senha:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senha),expression:"senha"}],staticClass:"input_default form-control",class:classes,attrs:{"type":"password"},domProps:{"value":(_vm.senha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senha=$event.target.value}}}),_c('span',_vm._b({staticClass:"position-absolute",class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-md-4",attrs:{"tag":"div","rules":"required","vid":"confirm","name":"Confirmação senha","id":"passConfirmCont"},nativeOn:{"blur":function($event){return _vm.validate($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
var validate = ref.validate;
return [_c('label',{staticClass:"label_default"},[_vm._v("Confirmar nova senha:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmaSenha),expression:"confirmaSenha"}],staticClass:"input_default form-control",attrs:{"type":"password"},domProps:{"value":(_vm.confirmaSenha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmaSenha=$event.target.value}}})]}}])}),_c('div',{staticClass:"col-md-4"},[_c('button',{staticClass:"btn_default"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.disabled),expression:"disabled"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(!_vm.disabled ? "Salvar" : "")+" ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }