<template>
  <div class="perfil py-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
      <router-link to="/" class="imgWelCont">
        <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
      </router-link>
      <div class="txtWelCont">
        <p>Meu Perfil</p>
      </div>
    </div>
    <div class="container">
      <div class="col-md-9 mx-auto">
        <p class="my-3 titulo_dash" v-if="this.sizeWindow > 767">Meu perfil</p>
        <div class="box box px-3 py-4">
          <div class="row h-100">
            <div class="col-md-11 mx-auto px-0">
              <campos class="row" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 mx-auto" id="divUnidades">
        <p class="my-3 titulo_dash">Unidades</p>
        <div class="box box px-3 py-4" id="unidadesCont">
          <unidade />
        </div>
      </div>
      <div class="col-md-9 mx-auto">
        <p class="my-3 titulo_dash">Senha</p>
        <div class="box box px-3 py-4">
          <senha />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import campos from "@/components/perfil/campos_dados";
import unidade from "@/components/perfil/unidade";
import senha from "@/components/perfil/senha";

export default {
  components: {
    campos,
    unidade,
    senha
  },
  data() {
    return {
      minHeight: window.innerHeight - 140,
      sizeWindow: 0,
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  methods: {}
};
</script>

<style scoped>
.perfil {
  background-color: #2474b2;
}
.list_h4 {
  color: #ffffff;
  font-size: 16px;
}

.box {
  background-color: #357eb8;

  height: auto;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
  border-radius: 20px;
  align-items: center;
}
@media screen and (max-width:767px){
  .grayArrow{
    cursor: pointer;
  }
  .perfil{ 
    padding-top: 0!important;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
  }
  .imgWelCont{
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 25%;
  }
  .box{
    background: #2474b2;
    box-shadow: unset;
    border-radius: 0;
  }
  #divUnidades p{
    border-bottom: 1px #3F88C1 solid;
    padding-bottom: 3%;
  }
  #unidadesCont{
    background: #3F88C1;
    border-radius: 8px;
  }
}
</style>